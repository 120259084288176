
export enum eRoles {
    uploader = "uploader",
    reviewer = "reviewer",
    hq = "hq",
    dbs = "dbs",
    dbo = "dbo",
    dbsAdmin = "dbsAdmin",
}
export enum eDbbsRole {  
    dbs = "dbs",
    dbo = "dbo",
    dbsAdmin = "dbsAdmin",
}
export enum docFileStatus{
    open = "open",    
    assigned = "assigned",
    verified = "verified",
    validating = "validating",
    detailing = "detailing",
    posted = "posted",
    resolved = "resolved",
    clientProcessing = "clientProcessing",
    dbbsProcessing = "dbbsProcessing",
    reviewing = "reviewing",
    done = "done",
    archived = "archived",
}

export enum docFileStage{
    reviewed = "processing",
    rejected = "query",
    done = "done",
    archived = "archived",
}
export enum ChatterAs{
    client = "client",
    dbs = "dbs",
    dbo = "dbo",
    dbbsAdmin = "dbbsAdmin",
}